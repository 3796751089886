import { selectLandingpage } from '@/reducers/categoriesReducer';
import Link from 'next/link';
import React, { useState } from 'react';
import { FaStar, FaDollarSign, FaRegStar } from 'react-icons/fa'; // Import icons
import { useSelector } from 'react-redux';

export default function MostHiredFreelancers() {
    const landingPageData = useSelector(selectLandingpage);
    const [errorImages, setErrorImages] = useState({});


    // Handle image error by tracking which image has failed
    const handleImageError = (userId) => {
        setErrorImages((prev) => ({
            ...prev,
            [userId]: true, // Mark this specific user's image as having an error
        }));
    };

    return (


        <div className='mt-4'>
            <div className="container mt-3 mx-auto px-4 py-6">
                <h2 className="text-3xl font-bold text-center mb-8 text-orange-600">Top Rated Freelancers</h2>
                <p className="text-gray-700 items-center text-center mb-3">Work with talented people at the most affordable price</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
                    {landingPageData?.freelancers?.map((freelancer) => (
                        <div
                            key={freelancer.id}
                            className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
                        >
                            <div className="p-3 flex flex-col items-center">
                                <div className="w-28 h-28 -mt-2 mb-3 relative">
                                    <img
                                        src={freelancer.avatar}
                                        alt={freelancer.firstName}
                                        className="w-full h-full rounded-full object-fill  object-right-bottom"
                                        onError={(e) => {
                                            e.target.src = "https://images.unsplash.com/photo-1511367461989-f85a21fda167";
                                        }}
                                    />
                                </div>
                                <h3 className="text-lg font-semibold text-gray-800 mb-1 text-center">
                                    {freelancer.firstName} {freelancer.lastName.charAt(0)}.                            </h3>
                                <div>
                                    <p className="text-gray-600 text-base m-2 items-start  line-clamp-2 text-center mb-3 px-2">
                                        {freelancer.bio}
                                    </p>
                                </div>

                                <div>
                                    <p className="flex items-center mt-2 text-flamingo-400">
                                        <FaDollarSign className="w-4 h-4 text-green-600 mr-1" />
                                        {freelancer.rate} /hr
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
